import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BreadCrumbs from '../components/common/breadcrumbs'


const PortfolioDetails = ({ data }) => {

	const breadcrumbs = {
		'Home': '/',
		'Portfolio': '/portfolio',
		[data.nodePortfolio.title]: null
	}

	return (
		<Layout pageTitle={data.nodePortfolio.title}>
			 <SEO title={data.nodePortfolio.title}/>

			<div className="page-portfolio-details">
				<div className="page-breadcrumbs notification">
					<BreadCrumbs input={breadcrumbs} />
				</div>
				<Img fluid={data.nodePortfolio.relationships.field_image.localFile.childImageSharp.fluid} />
				<div className="blog-content" dangerouslySetInnerHTML={{ __html: data.nodePortfolio.body.processed }}>
				</div>
			</div>
		</Layout>
	)
}
export default PortfolioDetails

export const query = graphql`query($path: String!) {
  nodePortfolio(path: { alias: { eq: $path } }) {
    id
    title
    status
    body {
      value
      format
      processed
      summary
		}
    relationships {
      field_image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
}`

